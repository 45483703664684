import React, { useEffect, useMemo, useState, useCallback } from "react";
import { Button, Modal } from "semantic-ui-react";
// UI Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import { SelectedItemHandler } from "react-lib/appcon/common/SearchBoxDropdown";

// UI ANE
import CardPreAnestheticFormUX from "./CardPreAnestheticFormUX";
import CardPreAnestheticTabUX from "./CardPreAnestheticTabUX";
import CardPreAnestheticHistoryUX from "./CardPreAnestheticHistoryUX";
import CardPreAnestheticAirwayEvaluationUX from "./CardPreAnestheticAirwayEvaluationUX";
import CardPreAnestheticBloodChemistryUX from "./CardPreAnestheticBloodChemistryUX";
import CardPreAnestheticProblemsConsiderationsUX from "./CardPreAnestheticProblemsConsiderationsUX";
import CardPreAnestheticRiskUX from "./CardPreAnestheticRiskUX";
import CardPreAnestheticReEvaluationUX from "./CardPreAnestheticReEvaluationUX";
import CardPhysicianEvaluation from "react-lib/apps/HISV3/ANE/CardPhysicianEvaluation";
import SubAssistantsInput from "../ORM/SubAssistantsInput";
import SearchBoxWithKey from "react-lib/appcon/common/SearchBoxWithKey";
//
import CardANSLog from "react-lib/apps/ANS/CardANSLog";
// seq
import { FORM_DATA_ACTION, PRE_ANESTHETIC_RE_EVALUATION } from "./sequence/PreAnesthetic";
// CONFIG
import CONFIG from "config/config";
import { useIntl } from "react-intl";

const CARD_KEY: string = "CardPreAnesthetic";
const FORM_CODE: string = "CardPreanestheticAnesthesiaRecord";
const FORM_VERSION: string = "1.0";

type CardPreAnestheticProps = {
  // function
  onEvent: any;
  setProp: any;

  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;

  // options
  masterOptions?: any;
  masterData?: any;

  // seq
  runSequence?: any;
  PreAnestheticSequence?: any;
  selectedDoctor?: any;
  selectedAnesthesiologist?: any;
  searchedItemListWithKey?: any;
  HistoryCentralLabSequence?: any;
  selectedOrOrder?: any;
  selectedEncounter?: any;
  isPHV?: boolean;
};

const preAnestheticTabs = [
  { code: "History", name: "History" },
  { code: "PhysicianEvaluation", name: "Physician Evaluation" },
  { code: "AirwayEvaluation", name: "Airway Evaluation" },
  { code: "BloodChemistry", name: "Blood chemistry and investigation" },
  { code: "AnestheticProblems", name: "Anesthetic problems / considerations" },
  { code: "RiskAnesthesia", name: "Risk of anesthesia included and informed" },
  { code: "ReEvaluation", name: "Preanesthetic re-evaluation" },
];

const CardPreAnesthetic = (props: CardPreAnestheticProps) => {
  const intl = useIntl();
  const [tab, setTab] = useState<string>("History");

  useEffect(() => {
    props.runSequence({
      sequence: "PreAnesthetic",
      action: "FETCH_PRE_ANESTHETIC",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formVersion: FORM_VERSION,
      restart: true,
    });
  }, []);

  useEffect(() => {
    if (props.selectedOrOrder) {
      props.runSequence({
        sequence: "PreAnesthetic",
        action: "FETCH_PRE_ANESTHETIC",
        cardKey: CARD_KEY,
        formCode: FORM_CODE,
        formVersion: FORM_VERSION,
        restart: true,
      });
    }
  }, [props.selectedOrOrder]);

  useEffect(() => {
    if (tab === "AnestheticProblems") {
      props.runSequence({
        sequence: "PreAnesthetic",
        action: "INIT_ANESTHETIC_PROBLEMS",
      });
    }
  }, [tab]);

  const mapOptions = useCallback((items: any) => {
    return (items || []).map((item: any) => ({
      key: item.id,
      value: item.id,
      text: item.name_code || item.full_name_code || item.full_name,
      content: item.full_name,
    }));
  }, []);

  const buttonLists = useMemo(() => {
    return preAnestheticTabs.filter(
      (items: any) => !CONFIG.V3_HIDDEN_TAB_LIST.includes(items.code)
    );
  }, [CONFIG.V3_HIDDEN_TAB_LIST]);

  const drugNameListPremedicationOptions = useMemo(() => {
    return [
      ...(props.masterOptions?.drugName || []),
      { key: "Other", value: "Other", text: "Other" },
    ];
  }, [props.masterOptions?.drugName]);

  // useEffect(() => {
  //   props.runSequence({
  //     sequence: "PreAnesthetic",
  //     action: "FETCH_PRE_ANESTHETIC",
  //     cardKey: CARD_KEY,
  //     formCode: FORM_CODE,
  //     formVersion: FORM_VERSION,
  //   });
  // }, []);

  // #useEffect(() => {
  //   if (
  //     props.PreAnestheticSequence?.data?.Anesthesiologist !== "" &&
  //     props.masterData?.doctor instanceof Array
  //   ) {
  //     let doctorData = props.masterData?.doctor?.filter(
  //       (item: any) =>
  //         item.id === props.PreAnestheticSequence?.data?.Anesthesiologist
  //     );

  //     if (doctorData?.[0]?.user !== undefined) {
  //       props.runSequence({
  //         sequence: "PreAnesthetic",
  //         action: "GET_LICENSE",
  //         cardKey: "Anesthesiologist",
  //         paramKey: doctorData?.[0]?.user,
  //       });
  //     }
  //   }
  // }, [props.PreAnestheticSequence?.data?.Anesthesiologist]);

  // #useEffect(() => {
  //   if (
  //     props.PreAnestheticSequence?.data?.Anesthetist !== "" &&
  //     props.masterData?.doctor instanceof Array
  //   ) {
  //     let doctorData = props.masterData.doctor?.filter(
  //       (item: any) =>
  //         item.id === props.PreAnestheticSequence?.data?.Anesthetist
  //     );

  //     if (doctorData?.[0]?.user !== undefined) {
  //       props.runSequence({
  //         sequence: "PreAnesthetic",
  //         action: "GET_LICENSE",
  //         cardKey: "Anesthetist",
  //         paramKey: doctorData[0]?.user,
  //       });
  //     }
  //   }
  // }, [props.PreAnestheticSequence?.data?.Anesthetist]);

  const handleChangeContainerName = (_event: any, data: any) => {
    setTab(data.name);
  };

  const handleChangeData = (_event: any, data: any) => {
    if (data.type === "checkbox") {
      if (
        data.name === "PreanestheticReEvaluationNoneDueto" &&
        data.checked &&
        data.value === false
      ) {
        props.setProp("PreAnestheticSequence.data", {
          ...props.PreAnestheticSequence,
          [data.name]: data.checked,
          ...PRE_ANESTHETIC_RE_EVALUATION,
        });
      } else {
        props.setProp(`PreAnestheticSequence.data.${data.name}`, data.checked);
      }
    } else {
      if (preAnestheticHistory.includes(data?.name) && (data.value === "No" || data.value === "")) {
        props.setProp(`PreAnestheticSequence.data`, {
          ...props.PreAnestheticSequence?.data,
          [`${data.name}`]: data.value,
          [`${data.name}Remark`]: "",
        });
      } else if (
        preAnestheticAirwayEvaluation.includes(data?.name) &&
        (data.value === "Normal" || data.value === "")
      ) {
        props.setProp(`PreAnestheticSequence.data`, {
          ...props.PreAnestheticSequence?.data,
          [`${data.name}`]: data.value,
          [`${data.name}Remark`]: "",
        });
      } else {
        props.setProp(`PreAnestheticSequence.data.${data.name}`, data.value);
      }
    }
  };

  const handleGetUserTokenizeLicense =
    (searchBox: any): SelectedItemHandler =>
    async (value, key, data) => {
      const searchedList = (props.searchedItemListWithKey?.[searchBox.id] || []).map((item: any) =>
        item.id === data?.id
          ? { ...item, full_name_code: item.full_name, name_code: item.full_name }
          : item
      );

      props.setProp(`searchedItemListWithKey.${searchBox.id}`, [...searchedList]);

      props.runSequence({
        sequence: "PreAnesthetic",
        action: "GET_USER_TOKENIZE_LICENSE",
        cardKey: CARD_KEY,
        data,
        searchBox,
      });
    };

  const handleCloseModLog = () => {
    props.setProp("PreAnestheticSequence.modLogInfo", {
      open: false,
      logList: [],
    });
  };

  const handleUpdateScrubNurse = (index: number) => (items: any[]) => {
    props.setProp(`PreAnestheticSequence.data.AnesthesiaNurse`, items);
  };

  const handleAnesthesiologist = (value: any) => {
    handleChangeData(null, { name: "Anesthesiologist", value });
  };

  return (
    <div>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_KEY}`, null);
          props.setProp(`successMessage.${CARD_KEY}`, null);
        }}
        error={props.errorMessage?.[CARD_KEY]}
        success={props.successMessage?.[CARD_KEY]}
        languageUX={props.languageUX}
      />
      <div>
        <CardPreAnestheticFormUX
          // function
          onChangeData={handleChangeData}
          // onKeyAnesthesiologist={(event: any) =>
          //   handleGetUserTokenizeLicense(event, "Anesthesiologist")
          // }
          // onKeyAnesthetist={(event: any) =>
          //   handleGetUserTokenizeLicense(event, "Anesthetist")
          // }
          // options
          masterOptions={props.masterOptions}
          // seq
          PreAnestheticSequence={props.PreAnestheticSequence}
          // component
          isPHV={props.isPHV}
          buttonLog={
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={() => {
                props.runSequence({
                  sequence: "PreAnesthetic",
                  action: "LOG",
                  cardKey: CARD_KEY,
                  formCode: FORM_CODE,
                  formVersion: FORM_VERSION,
                });
              }}
              // data
              paramKey={`${CARD_KEY}_LOG`}
              buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_LOG`]}
              // config
              color="orange"
              size="medium"
              style={{ width: "120px" }}
              title="Log"
            />
          }
          buttonPrint={
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={() => {
                props.runSequence({
                  sequence: "PreAnesthetic",
                  action: "PRINT",
                  cardKey: CARD_KEY,
                });
              }}
              // data
              paramKey={`${CARD_KEY}_PRINT`}
              buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_PRINT`]}
              // config
              color="blue"
              size="medium"
              style={{ width: "120px", ...(props.isPHV && { display: "none" }) }}
              title="Print"
            />
          }
          buttonSave={
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={() => {
                props.runSequence({
                  sequence: "PreAnesthetic",
                  action: FORM_DATA_ACTION.SAVE,
                  cardKey: CARD_KEY,
                  formCode: FORM_CODE,
                  formVersion: FORM_VERSION,
                });
              }}
              // data
              paramKey={`${CARD_KEY}_${FORM_DATA_ACTION.SAVE}`}
              buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_${FORM_DATA_ACTION.SAVE}`]}
              // config
              color={"orange"}
              size="medium"
              style={{ width: "120px", ...(props.isPHV && { display: "none" }) }}
              title={"Save Draft"}
            />
          }
          buttonConfirm={
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={() => {
                props.runSequence({
                  sequence: "PreAnesthetic",
                  action: FORM_DATA_ACTION.CONFIRM,
                  cardKey: CARD_KEY,
                  formCode: FORM_CODE,
                  formVersion: FORM_VERSION,
                });
              }}
              // data
              paramKey={`${CARD_KEY}_${FORM_DATA_ACTION.CONFIRM}`}
              buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_${FORM_DATA_ACTION.CONFIRM}`]}
              // config
              color="green"
              size="medium"
              style={{ width: "120px", ...(props.isPHV && { display: "none" }) }}
              title="Confirm"
            />
          }
          SurgeonElement={
            <SearchBoxWithKey
              // callback
              onEvent={props.onEvent}
              type="Doctor"
              id={"Anesthesiologist_1"}
              searchedItemListWithKey={props.searchedItemListWithKey}
              selectedItem={props.PreAnestheticSequence?.data?.Anesthesiologist || null}
              setSelected={handleAnesthesiologist}
            />
          }
          AnesthetistElement={
            <SubAssistantsInput
              // callback
              onEvent={props.onEvent}
              // data
              index={0}
              items={props.PreAnestheticSequence?.data?.AnesthesiaNurse || []}
              type="User"
              role="REGISTERED_NURSE"
              field="Anesthetist"
              hideDateTime={true}
              searchedItemListWithKey={props.searchedItemListWithKey}
              onUpdated={handleUpdateScrubNurse(0)}
              languageUX={props.languageUX}
            />
          }
          languageUX={props.languageUX}
        />

        {/* <CardPreAnestheticTabUX onChangeContainerName={handleChangeContainerName} tab={tab} /> */}
        <div className="pre-anesthetic-tabs">
          <div style={{ display: "flex", padding: "10px" }}>
            {buttonLists.map((items: any) => (
              <Button
                name={items?.code}
                onClick={handleChangeContainerName}
                style={{ color: tab === items?.code ? "blue" : "grey" }}
              >
                {items?.name}
              </Button>
            ))}
          </div>
          <div style={{ width: "100%", marginLeft: "10px" }}>
            <hr></hr>
          </div>
        </div>

        <div style={{ ...(props.isPHV && { pointerEvents: "none" }) }}>
          {tab === "PhysicianEvaluation" ? (
            <CardPhysicianEvaluation
              onEvent={props.onEvent}
              setProp={props.setProp}
              runSequence={props.runSequence}
              masterOptions={props.masterOptions}
              masterData={props.masterData}
              PreAnestheticSequence={props.PreAnestheticSequence}
              buttonLoadCheck={props.buttonLoadCheck}
              HistoryCentralLabSequence={props.HistoryCentralLabSequence}
              selectedOrOrder={props.selectedOrOrder}
              selectedEncounter={props.selectedEncounter}
              languageUX={props.languageUX}
            />
          ) : tab === "AirwayEvaluation" ? (
            <CardPreAnestheticAirwayEvaluationUX
              onChangeData={handleChangeData}
              masterOptions={props.masterOptions}
              PreAnestheticSequence={props.PreAnestheticSequence}
              buttonSave={
                <ButtonLoadCheck
                  // function
                  setProp={props.setProp}
                  onClick={() => {
                    props.runSequence({
                      sequence: "PreAnesthetic",
                      action: FORM_DATA_ACTION.SAVE,
                      cardKey: "CardPreAnestheticAirwayEvaluation",
                      formCode: "CardPreAnestheticAirwayEvaluation",
                      formName: "CardPreAnestheticAirwayEvaluation",
                      formVersion: FORM_VERSION,
                      tabForm: true,
                    });
                  }}
                  // data
                  paramKey={`CardPreAnestheticAirwayEvaluation_${FORM_DATA_ACTION.SAVE}`}
                  buttonLoadCheck={
                    props.buttonLoadCheck?.[
                      `CardPreAnestheticAirwayEvaluation_${FORM_DATA_ACTION.SAVE}`
                    ]
                  }
                  // config
                  color={"orange"}
                  size="medium"
                  style={{ width: "120px" }}
                  title={"Save Draft"}
                />
              }
              languageUX={props.languageUX}
            />
          ) : tab === "BloodChemistry" ? (
            <CardPreAnestheticBloodChemistryUX
              onChangeData={handleChangeData}
              PreAnestheticSequence={props.PreAnestheticSequence}
              buttonSave={
                <ButtonLoadCheck
                  // function
                  setProp={props.setProp}
                  onClick={() => {
                    props.runSequence({
                      sequence: "PreAnesthetic",
                      action: FORM_DATA_ACTION.SAVE,
                      cardKey: "CardPreAnestheticBloodChemistry",
                      formCode: "CardPreAnestheticBloodChemistry",
                      formName: "CardPreAnestheticBloodChemistry",
                      formVersion: FORM_VERSION,
                      tabForm: true,
                    });
                  }}
                  // data
                  paramKey={`CardPreAnestheticBloodChemistry_${FORM_DATA_ACTION.SAVE}`}
                  buttonLoadCheck={
                    props.buttonLoadCheck?.[
                      `CardPreAnestheticBloodChemistry_${FORM_DATA_ACTION.SAVE}`
                    ]
                  }
                  // config
                  color={"orange"}
                  size="medium"
                  style={{ width: "120px" }}
                  title={"Save Draft"}
                />
              }
              languageUX={props.languageUX}
            />
          ) : tab === "AnestheticProblems" ? (
            <CardPreAnestheticProblemsConsiderationsUX
              onChangeData={handleChangeData}
              masterOptions={props.masterOptions}
              PreAnestheticSequence={props.PreAnestheticSequence}
              premadicationOptions={drugNameListPremedicationOptions}
              buttonSave={
                <ButtonLoadCheck
                  // function
                  setProp={props.setProp}
                  onClick={() => {
                    props.runSequence({
                      sequence: "PreAnesthetic",
                      action: FORM_DATA_ACTION.SAVE,
                      cardKey: "CardPreAnestheticProblemsConsiderations",
                      formCode: "CardPreAnestheticProblemsConsiderations",
                      formName: "CardPreAnestheticProblemsConsiderations",
                      formVersion: FORM_VERSION,
                      tabForm: true,
                    });
                  }}
                  // data
                  paramKey={`CardPreAnestheticProblemsConsiderations_${FORM_DATA_ACTION.SAVE}`}
                  buttonLoadCheck={
                    props.buttonLoadCheck?.[
                      `CardPreAnestheticProblemsConsiderations_${FORM_DATA_ACTION.SAVE}`
                    ]
                  }
                  // config
                  color={"orange"}
                  size="medium"
                  style={{ width: "120px" }}
                  title={"Save Draft"}
                />
              }
              languageUX={props.languageUX}
            />
          ) : tab === "RiskAnesthesia" ? (
            <CardPreAnestheticRiskUX
              onChangeData={handleChangeData}
              PreAnestheticSequence={props.PreAnestheticSequence}
              buttonSave={
                <ButtonLoadCheck
                  // function
                  setProp={props.setProp}
                  onClick={() => {
                    props.runSequence({
                      sequence: "PreAnesthetic",
                      action: FORM_DATA_ACTION.SAVE,
                      cardKey: "CardPreAnestheticRisk",
                      formCode: "CardPreAnestheticRisk",
                      formName: "CardPreAnestheticRisk",
                      formVersion: FORM_VERSION,
                      tabForm: true,
                    });
                  }}
                  // data
                  paramKey={`CardPreAnestheticRisk_${FORM_DATA_ACTION.SAVE}`}
                  buttonLoadCheck={
                    props.buttonLoadCheck?.[`CardPreAnestheticRisk_${FORM_DATA_ACTION.SAVE}`]
                  }
                  // config
                  color={"orange"}
                  size="medium"
                  style={{ width: "120px" }}
                  title={"Save Draft"}
                />
              }
              languageUX={props.languageUX}
            />
          ) : tab === "ReEvaluation" ? (
            <CardPreAnestheticReEvaluationUX
              onChangeData={handleChangeData}
              onChangeTimeForm={(value: string) =>
                props.setProp("PreAnestheticSequence.data.PREANESTHETICREEVALUATIONTime", value)
              }
              onChangeDateForm={(date: string) =>
                props.setProp("PreAnestheticSequence.data.PREANESTHETICREEVALUATIONDate", date)
              }
              // onKeyAnesthetsiologisAnesthetist={(event: any) =>
              //   handleGetUserTokenizeLicense(
              //     event,
              //     "PreanestheticReEvaluationAnesthetsiologisAnesthetist"
              //   )
              // }
              masterOptions={props.masterOptions}
              PreAnestheticSequence={props.PreAnestheticSequence}
              readOnly={props.PreAnestheticSequence?.data?.PreanestheticReEvaluationNoneDueto}
              anesthesiologistSearchBox={
                <SearchBoxWithKey
                  // callback
                  onEvent={props.onEvent}
                  type="User"
                  role="DOCTOR"
                  id="PARED"
                  searchedItemListWithKey={props.searchedItemListWithKey}
                  selectedItem={
                    props.PreAnestheticSequence?.data
                      ?.PreanestheticReEvaluationAnesthetsiologisAnesthetistId || null
                  }
                  inline
                  defaultText={
                    props.PreAnestheticSequence?.data
                      ?.PreanestheticReEvaluationAnesthetsiologisAnesthetist
                  }
                  mapOptions={mapOptions}
                  setSelected={handleGetUserTokenizeLicense({
                    id: "User_PARED",
                    name: "PreanestheticReEvaluationAnesthetsiologisAnesthetist",
                  })}
                />
              }
              anesthesiaNurseSearchBox={
                <SearchBoxWithKey
                  // callback
                  onEvent={props.onEvent}
                  type="User"
                  id="PAREN"
                  role="REGISTERED_NURSE"
                  searchedItemListWithKey={props.searchedItemListWithKey}
                  selectedItem={
                    props.PreAnestheticSequence?.data?.PreanestheticReEvaluationAnesthesiaNurseId ||
                    null
                  }
                  defaultText={
                    props.PreAnestheticSequence?.data?.PreanestheticReEvaluationAnesthesiaNurse
                  }
                  inline
                  mapOptions={mapOptions}
                  setSelected={handleGetUserTokenizeLicense({
                    id: "User_PAREN",
                    name: "PreanestheticReEvaluationAnesthesiaNurse",
                  })}
                />
              }
              buttonSave={
                <ButtonLoadCheck
                  // function
                  setProp={props.setProp}
                  onClick={() => {
                    props.runSequence({
                      sequence: "PreAnesthetic",
                      action: FORM_DATA_ACTION.SAVE,
                      cardKey: "CardPreAnestheticReEvaluation",
                      formCode: "CardPreAnestheticReEvaluation",
                      formName: "CardPreAnestheticReEvaluation",
                      formVersion: FORM_VERSION,
                      tabForm: true,
                    });
                  }}
                  // data
                  paramKey={`CardPreAnestheticReEvaluation_${FORM_DATA_ACTION.SAVE}`}
                  buttonLoadCheck={
                    props.buttonLoadCheck?.[
                      `CardPreAnestheticReEvaluation_${FORM_DATA_ACTION.SAVE}`
                    ]
                  }
                  // config
                  color={"orange"}
                  size="medium"
                  style={{ width: "120px" }}
                  title={"Save Draft"}
                />
              }
              languageUX={props.languageUX}
            />
          ) : (
            <CardPreAnestheticHistoryUX
              onChangeDate={(date: string) => {
                props.setProp("PreAnestheticSequence.data.FunctionalLMP", date);
              }}
              onChangeData={handleChangeData}
              masterOptions={props.masterOptions}
              PreAnestheticSequence={props.PreAnestheticSequence}
              preAnestheticHistoryDetail={props.PreAnestheticSequence?.preAnestheticHistoryDetail}
              buttonSave={
                <ButtonLoadCheck
                  // function
                  setProp={props.setProp}
                  onClick={() => {
                    props.runSequence({
                      sequence: "PreAnesthetic",
                      action: FORM_DATA_ACTION.SAVE,
                      cardKey: "CardPreAnestheticHistory",
                      formCode: "CardPreAnestheticHistory",
                      formName: "CardPreAnestheticHistory",
                      formVersion: FORM_VERSION,
                      tabForm: true,
                    });
                  }}
                  // data
                  paramKey={`CardPreAnestheticHistory_${FORM_DATA_ACTION.SAVE}`}
                  buttonLoadCheck={
                    props.buttonLoadCheck?.[`CardPreAnestheticHistory_${FORM_DATA_ACTION.SAVE}`]
                  }
                  // config
                  color={
                    props.PreAnestheticSequence?.preAnestheticHistoryDetail?.status === "DRAFT"
                      ? "orange"
                      : "green"
                  }
                  size="medium"
                  style={{ width: "120px" }}
                  title={
                    props.PreAnestheticSequence?.preAnestheticHistoryDetail?.status === "DRAFT"
                      ? "Save Draft"
                      : "Save"
                  }
                />
              }
              languageUX={props.languageUX}
            />
          )}
        </div>
      </div>
      <Modal
        open={props.PreAnestheticSequence?.modLogInfo?.open}
        closeOnDimmerClick={true}
        onClose={handleCloseModLog}
      >
        <CardANSLog
          showAction={true}
          createDetail={true}
          dataList={props.PreAnestheticSequence?.modLogInfo?.logList || []}
          hideCallback={handleCloseModLog}
          languageUX={props.languageUX}
        />
      </Modal>
    </div>
  );
};

export default React.memo(CardPreAnesthetic);

const preAnestheticHistory = [
  "PreviousAnesthesia",
  "Complication",
  "KnownDisease",
  "FamilyHistory",
  "CurrentMedications",
];

const preAnestheticAirwayEvaluation = [
  "Respiratory",
  "Cardiovascular",
  "Neurologic",
  "EENT",
  "GI",
  "Genitourinary",
  "EndocrineAndMetabolic",
  "SkinBreast",
  "ImmunologyHematology",
];
