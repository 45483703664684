import React, { useEffect } from "react";
import { Modal } from "semantic-ui-react";
// UI Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import SnackMessage from "react-lib/apps/common/SnackMessage";
// UI ANE
import CardPACURecordFormUX from "./CardPACURecordFormUX";
import CardPeriDataSheetFormUX from "./CardPeriDataSheetFormUX";
//
import CardANSLog from "react-lib/apps/ANS/CardANSLog";
import TimeFreeTextBox24 from "react-lib/apps/common/TimeFreeTextBox24";
import moment from "moment";
import { useIntl } from "react-intl";

const CARD_KEY: string = "CardPeriDataSheet";
const FORM_CODE: string = "CardPerianestheticDataSheet";
const FORM_NAME: string = "CardPerianestheticDataSheet";
const FORM_VERSION: string = "1.0";

type CardPeriDataSheetProps = {
  // function
  onEvent: any;
  setProp: any;

  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;

  // options
  masterOptions?: any;

  // seq
  runSequence?: any;
  PACURecordSequence?: any;
  PeriDataSheetSequence?: any;
  selectedOrOrder?: any;
  isPHV?: boolean;
};

const CardPeriDataSheet = (props: CardPeriDataSheetProps) => {
  const intl = useIntl();

  // PACURecord
  useEffect(() => {
    props.runSequence({
      sequence: "PACURecord",
      action: "FETCH_PACU",
      cardKey: "CardPacuRecord",
      formCode: "CardPACURecord",
      formVersion: "1.0",
    });
  }, []);

  // PeriDataSheet
  useEffect(() => {
    props.runSequence({
      sequence: "PeriDataSheet",
      action: "FETCH_PERI",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formVersion: FORM_VERSION,
    });
  }, []);

  useEffect(() => {
    if (props.selectedOrOrder) {
      props.runSequence({
        sequence: "PACURecord",
        action: "FETCH_PACU",
        cardKey: "CardPacuRecord",
        formCode: "CardPACURecord",
        formVersion: "1.0",
      });

      props.runSequence({
        sequence: "PeriDataSheet",
        action: "FETCH_PERI",
        cardKey: CARD_KEY,
        formCode: FORM_CODE,
        formVersion: FORM_VERSION,
      });
    }
  }, [props.selectedOrOrder]);

  const handleChangeData = (_event: any, data: any) => {
    if (data.type === "checkbox") {
      props.setProp(`PeriDataSheetSequence.data.${data.name}`, data.checked);
    } else {
      props.setProp(`PeriDataSheetSequence.data.${data.name}`, data.value);
    }
  };

  const handleSetTime = () => {
    let dataRecord = props.PeriDataSheetSequence?.data;
    let newEndDate = moment(
      `${dataRecord?.EndDate} ${dataRecord?.EndAnesRemark}`,
      "DD/MM/YYYY HH:mm"
    );
    let newStartDate = moment(
      `${dataRecord?.StartDate} ${dataRecord?.StartOpRemark}`,
      "DD/MM/YYYY HH:mm"
    );

    let totalTime = newEndDate.diff(newStartDate, "minutes");

    props.setProp("PeriDataSheetSequence.data.PACUDurartionTime", totalTime || 0);
  };

  // -------------------- Modal Log
  const handleCloseModLog = () => {
    props.setProp("PeriDataSheetSequence.modLogInfo", {
      open: false,
      logList: [],
    });
  };

  return (
    <>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_KEY}`, null);
          props.setProp(`successMessage.${CARD_KEY}`, null);
        }}
        error={props.errorMessage?.[CARD_KEY]}
        success={props.successMessage?.[CARD_KEY]}
        languageUX={props.languageUX}
      />

      <CardPACURecordFormUX
        // seq
        isPHV={props.isPHV}
        PACURecordSequence={props.PACURecordSequence}
        buttonStatus={props.PeriDataSheetSequence?.status}
        masterOptions={props.masterOptions}
        // component
        startTime={
          <div style={{ display: "flex", height: "100%", width: "100%" }}>
            <TimeFreeTextBox24
              className="time-custom-react-picker"
              onChange={(value: string) => {
                props.setProp("PACURecordSequence.data.StartOpRemark", value);
                handleSetTime();
              }}
              value={props.PACURecordSequence?.data?.StartOpRemark || ""}
              iconClockStyle={{ margin: "auto 30px" }}
              autoFocus={false}
              checkTimeOverflow={true}
              disabled={true}
            ></TimeFreeTextBox24>
          </div>
        }
        endTime={
          <div style={{ display: "flex", height: "100%", width: "100%" }}>
            <TimeFreeTextBox24
              className="time-custom-react-picker"
              onChange={(value: string) => {
                props.setProp("PACURecordSequence.data.EndAnesRemark", value);
                handleSetTime();
              }}
              value={props.PACURecordSequence?.data?.EndAnesRemark || ""}
              iconClockStyle={{ margin: "auto 30px" }}
              autoFocus={false}
              checkTimeOverflow={true}
              disabled={true}
            ></TimeFreeTextBox24>
          </div>
        }
        buttonLog={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => {
              props.runSequence({
                sequence: "PeriDataSheet",
                action: "LOG",
                cardKey: CARD_KEY,
              });
            }}
            // data
            paramKey={`${CARD_KEY}_LOG`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_LOG`]}
            // config
            color="orange"
            size="medium"
            style={{ width: "120px" }}
            title="Log"
          />
        }
        buttonPrint={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => {
              props.runSequence({
                sequence: "PeriDataSheet",
                action: "PRINT",
                cardKey: CARD_KEY,
              });
            }}
            // data
            paramKey={`${CARD_KEY}_PRINT`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_PRINT`]}
            // config
            color="blue"
            size="medium"
            style={{ width: "120px", ...(props.isPHV && { display: "none" }) }}
            title="Print"
          />
        }
        buttonSave={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => {
              props.runSequence({
                sequence: "PeriDataSheet",
                action: "SAVE",
                cardKey: CARD_KEY,
                formCode: FORM_CODE,
                formName: FORM_NAME,
                formVersion: FORM_VERSION,
              });
            }}
            // data
            paramKey={`${CARD_KEY}_SAVE`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_SAVE`]}
            // config
            color={"orange"}
            size="medium"
            style={{ width: "120px", ...(props.isPHV && { display: "none" }) }}
            title={"Save Draft"}
          />
        }
        buttonConfirm={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => {
              props.runSequence({
                sequence: "PeriDataSheet",
                action: "CONFIRM",
                cardKey: CARD_KEY,
                formCode: FORM_CODE,
                formName: FORM_NAME,
                formVersion: FORM_VERSION,
              });
            }}
            // data
            paramKey={`${CARD_KEY}_CONFIRM`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_CONFIRM`]}
            // config
            color="green"
            size="medium"
            style={{ width: "120px", ...(props.isPHV && { display: "none" }) }}
            title="Confirm"
          />
        }
        isPACU={false}
        languageUX={props.languageUX}
      />

      <div style={{ ...(props.isPHV && { pointerEvents: "none" }) }}>
        <CardPeriDataSheetFormUX
          onChangeData={handleChangeData}
          masterOptions={props.masterOptions}
          PeriDataSheetSequence={props.PeriDataSheetSequence}
          languageUX={props.languageUX}
        />
      </div>

      <Modal
        open={props.PeriDataSheetSequence?.modLogInfo?.open}
        closeOnDimmerClick={true}
        onClose={handleCloseModLog}
      >
        <CardANSLog
          showAction={true}
          editDetail={true}
          dataList={props.PeriDataSheetSequence?.modLogInfo?.logList || []}
          hideCallback={handleCloseModLog}
          languageUX={props.languageUX}
        />
      </Modal>
    </>
  );
};

export default React.memo(CardPeriDataSheet);
