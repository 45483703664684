import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Form,
  FormGroup,
  FormField,
  Dropdown,
  Input
} from 'semantic-ui-react'

const CardPreAnestheticFormUX = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        className="--override-disabled --grey"
        id="CardPreAnestheticForm"
        style={{ width: "100%", padding:"10px"}}>
        <div
          id="CardPreAnestheticFormUX-div-3">
          
          <div
            id="CardPreAnestheticFormUX-div-4"
            style={{ gridRow: "1/2", gridColumn: "1/3",marginLeft:"10px",marginTop:"10px", justifyContent: "space-between", display: "flex", alignItems: "center", marginBottom: "10px"}}>
            <div
              id="CardPreAnestheticFormUX-div-5"
              style={{ display: "flex" ,fontWeight:"Bold",fontSize:"18px",marginBottom:"10px"}}>
              Pre Anesthetic form
            </div>
            <div
              id="CardPreAnestheticFormUX-div-156"
              style={{display: "flex" }}>
              
              <div
                id="CardPreAnestheticFormUX-div-157">
                {props.buttonLog}
              </div>
              <div
                id="CardPreAnestheticFormUX-div-158"
                style={{ display: "none" }}>
                {props.buttonPrint}
              </div>
              <div
                id="CardPreAnestheticFormUX-div-161"
                style={{ display: props.PreAnestheticSequence?.status_label === "Confirm" ?  "none" : "",}}>
                {props.buttonSave}
              </div>
              <div
                id="CardPreAnestheticFormUX-div-160"
                style={{ display: props.PreAnestheticSequence?.status_label === "Confirm" ?  "none" : "",}}>
                {props.buttonConfirm}
              </div>
            </div>
          </div>
        </div>
        <div
          className="--override-disabled --grey"
          id="CardPreAnestheticFormUX-div-1"
          style={{backgroundColor: "#EDF6F9",width: "100%",height:"50%",padding:"20px 0px 10px 10px",borderRadius: 3,border : "solid 0.5px ",borderColor:"#5DBCD2", ...(props.isPHV && { pointerEvents: "none" })}}>
          
          <Form
            id="CardPreAnestheticFormUX-Form-164">
            <FormGroup
              id="CardPreAnestheticFormUX-FormGroup-165"
              inline={true}>
              <FormField
                id="CardPreAnestheticFormUX-FormField-166"
                inline={true}
                style={{ marginRight: "10px" }}
                width={7}>
                <label
                  id="CardPreAnestheticFormUX-label-169"
                  style={{minWidth: "100px", maxWidth: "100px"}}>
                  Preanesthetic assessment at
                </label>
                <Dropdown
                  clearable={true}
                  fluid={true}
                  id="CardPreAnestheticFormUX-Dropdown-178"
                  name="PreanestheticAssessmentAt"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.preanes_assessment_at || []}
                  search={true}
                  selection={true}
                  style={{marginRight: "0.5rem", width: "100%"}}
                  value={props.PreAnestheticSequence?.data?.PreanestheticAssessmentAt || ""}>
                </Dropdown>
                <Input
                  fluid={true}
                  id="CardPreAnestheticFormUX-Input-179"
                  name="PreanestheticAssessmentAtRemark"
                  onChange={props.onChangeData}
                  value={props.PreAnestheticSequence?.data?.PreanestheticAssessmentAtRemark || ""}>
                </Input>
              </FormField>
              <FormField
                id="CardPreAnestheticFormUX-FormField-251"
                inline={true}
                style={{ display: "flex", justifyContent: "center" }}
                width={3}>
                <label
                  id="CardPreAnestheticFormUX-label-253"
                  style={{minWidth: "80px", maxWidth: "80px", fontSize: "13px"}}>
                  Body weight
                </label>
                <div
                  id="CardPreAnestheticFormUX-div-259"
                  style={{minWidth: "80px", maxWidth: "80px"}}>
                  
                  <Input
                    disabled={true}
                    fluid={true}
                    id="CardPreAnestheticFormUX-Input-260"
                    style={{ width: "100%" }}
                    value={props.PreAnestheticSequence?.data?.BodyWeight || ""}>
                  </Input>
                </div>
                <label
                  id="CardPreAnestheticFormUX-label-254"
                  style={{minWidth: "30px", maxWidth: "30px", margin: "0px 10px"}}>
                  kg
                </label>
              </FormField>
              <FormField
                className="--override-disabled --grey"
                id="CardPreAnestheticFormUX-FormField-168"
                inline={true}
                style={{ display: "flex", justifyContent: "center" }}
                width={3}>
                <label
                  id="CardPreAnestheticFormUX-label-172"
                  style={{minWidth: "45px", maxWidth: "45px" }}>
                  Height
                </label>
                <div
                  id="CardPreAnestheticFormUX-div-261"
                  style={{minWidth: "80px", maxWidth: "80px"}}>
                  
                  <Input
                    disabled={true}
                    fluid={true}
                    id="CardPreAnestheticFormUX-Input-262"
                    style={{ width: "100%" }}
                    value={props.PreAnestheticSequence?.data?.Height || ""}>
                  </Input>
                </div>
                <label
                  id="CardPreAnestheticFormUX-label-173"
                  style={{minWidth: "30px", maxWidth: "30px", margin: "0px 10px"}}>
                  cm
                </label>
              </FormField>
              <FormField
                id="CardPreAnestheticFormUX-FormField-252"
                inline={true}
                style={{ display: "flex", justifyContent: "center" }}
                width={3}>
                <label
                  id="CardPreAnestheticFormUX-label-255"
                  style={{minWidth: "35px", maxWidth: "35px" }}>
                  BMI
                </label>
                <div
                  id="CardPreAnestheticFormUX-div-263"
                  style={{minWidth: "80px", maxWidth: "80px"}}>
                  
                  <Input
                    disabled={true}
                    fluid={true}
                    id="CardPreAnestheticFormUX-Input-264"
                    style={{ width: "100%" }}
                    value={props.PreAnestheticSequence?.data?.BMI || ""}>
                  </Input>
                </div>
                <label
                  id="CardPreAnestheticFormUX-label-256"
                  style={{minWidth: "40px", maxWidth: "40px", margin: "0px 10px"}}>
                  kg/m2
                </label>
              </FormField>
              <FormField
                id="CardPreAnestheticFormUX-FormField-265"
                inline={true}
                style={{ display: "flex", justifyContent: "center" }}
                width={3}>
                <label
                  id="CardPreAnestheticFormUX-label-266"
                  style={{minWidth: "65px", maxWidth: "65px"}}>
                  BP
                </label>
                <div
                  id="CardPreAnestheticFormUX-div-268"
                  style={{minWidth: "80px", maxWidth: "80px"}}>
                  
                  <Input
                    disabled={true}
                    fluid={true}
                    id="CardPreAnestheticFormUX-Input-269"
                    style={{ width: "100%" }}
                    value={props.PreAnestheticSequence?.data?.BP || ""}>
                  </Input>
                </div>
                <label
                  id="CardPreAnestheticFormUX-label-267"
                  style={{minWidth: "45px", maxWidth: "45px", margin: "0px 10px"}}>
                  mmHg
                </label>
              </FormField>
            </FormGroup>
            <FormGroup
              id="CardPreAnestheticFormUX-FormGroup-184"
              inline={true}>
              <FormField
                id="CardPreAnestheticFormUX-FormField-185"
                inline={true}
                style={{ marginRight: "10px" }}
                width={7}>
                <label
                  id="CardPreAnestheticFormUX-label-188"
                  style={{minWidth: "100px", maxWidth: "100px"}}>
                  Psychological status
                </label>
                <Dropdown
                  clearable={true}
                  fluid={true}
                  id="CardPreAnestheticFormUX-Dropdown-197"
                  name="PsychologicalStatus"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.preanes_psychological_status || []}
                  search={true}
                  selection={true}
                  style={{marginRight: "0.5rem", width: "100%"}}
                  value={props.PreAnestheticSequence?.data?.PsychologicalStatus || ""}>
                </Dropdown>
                <Input
                  fluid={true}
                  id="CardPreAnestheticFormUX-Input-198"
                  name="PsychologicalStatusRemark"
                  onChange={props.onChangeData}
                  value={props.PreAnestheticSequence?.data?.PsychologicalStatusRemark || ""}>
                </Input>
              </FormField>
              <FormField
                id="CardPreAnestheticFormUX-FormField-270"
                inline={true}
                style={{ display: "flex", justifyContent: "center" }}
                width={3}>
                <label
                  id="CardPreAnestheticFormUX-label-273"
                  style={{minWidth: "80px", maxWidth: "80px", fontSize: "13px"}}>
                  Pulse
                </label>
                <div
                  id="CardPreAnestheticFormUX-div-279"
                  style={{minWidth: "80px", maxWidth: "80px"}}>
                  
                  <Input
                    disabled={true}
                    fluid={true}
                    id="CardPreAnestheticFormUX-Input-284"
                    style={{ width: "100%" }}
                    value={props.PreAnestheticSequence?.data?.Pulse || ""}>
                  </Input>
                </div>
                <label
                  id="CardPreAnestheticFormUX-label-274"
                  style={{minWidth: "30px", maxWidth: "30px", margin: "0px 10px"}}>
                  /min
                </label>
              </FormField>
              <FormField
                id="CardPreAnestheticFormUX-FormField-271"
                inline={true}
                style={{ display: "flex", justifyContent: "center" }}
                width={3}>
                <label
                  id="CardPreAnestheticFormUX-label-275"
                  style={{minWidth: "45px", maxWidth: "45px" }}>
                  RR
                </label>
                <div
                  id="CardPreAnestheticFormUX-div-280"
                  style={{minWidth: "80px", maxWidth: "80px"}}>
                  
                  <Input
                    disabled={true}
                    fluid={true}
                    id="CardPreAnestheticFormUX-Input-283"
                    style={{ width: "100%" }}
                    value={props.PreAnestheticSequence?.data?.RR || ""}>
                  </Input>
                </div>
                <label
                  id="CardPreAnestheticFormUX-label-276"
                  style={{minWidth: "30px", maxWidth: "30px", margin: "0px 10px"}}>
                  /min
                </label>
              </FormField>
              <FormField
                id="CardPreAnestheticFormUX-FormField-272"
                inline={true}
                style={{ display: "flex", justifyContent: "center" }}
                width={3}>
                <label
                  id="CardPreAnestheticFormUX-label-277"
                  style={{minWidth: "35px", maxWidth: "35px" }}>
                  Temp
                </label>
                <div
                  id="CardPreAnestheticFormUX-div-281"
                  style={{minWidth: "80px", maxWidth: "80px"}}>
                  
                  <Input
                    disabled={true}
                    fluid={true}
                    id="CardPreAnestheticFormUX-Input-282"
                    style={{ width: "100%" }}
                    value={props.PreAnestheticSequence?.data?.PsychologicalStatusTemp || ""}>
                  </Input>
                </div>
                <label
                  id="CardPreAnestheticFormUX-label-278"
                  style={{minWidth: "40px", maxWidth: "40px", margin: "0px 10px"}}>
                  °C
                </label>
              </FormField>
              <FormField
                id="CardPreAnestheticFormUX-FormField-285"
                inline={true}
                style={{ display: "flex", justifyContent: "center" }}
                width={3}>
                <label
                  id="CardPreAnestheticFormUX-label-286"
                  style={{minWidth: "65px", maxWidth: "65px"}}>
                  O2 Sat RA
                </label>
                <div
                  id="CardPreAnestheticFormUX-div-288"
                  style={{minWidth: "80px", maxWidth: "80px"}}>
                  
                  <Input
                    disabled={true}
                    fluid={true}
                    id="CardPreAnestheticFormUX-Input-289"
                    style={{ width: "100%" }}
                    value={props.PreAnestheticSequence?.data?.O2SatRA || ""}>
                  </Input>
                </div>
                <label
                  id="CardPreAnestheticFormUX-label-287"
                  style={{minWidth: "45px", maxWidth: "45px", margin: "0px 10px"}}>
                  %
                </label>
              </FormField>
            </FormGroup>
            <FormGroup
              id="CardPreAnestheticFormUX-FormGroup-235"
              inline={true}>
              <FormField
                className="--override-disabled --grey"
                id="CardPreAnestheticFormUX-FormField-236"
                inline={true}
                style={{ marginRight: "10px" }}
                width={4}>
                <label
                  id="CardPreAnestheticFormUX-label-238"
                  style={{minWidth: "95px", maxWidth: "95px"}}>
                  DX
                </label>
                <Input
                  disabled={true}
                  fluid={true}
                  id="CardPreAnestheticFormUX-Input-240"
                  value={props.PreAnestheticSequence?.data?.DX || ""}>
                </Input>
              </FormField>
              <FormField
                className="--override-disabled --grey"
                id="CardPreAnestheticFormUX-FormField-237"
                inline={true}
                style={{ marginRight: "10px" }}
                width={4}>
                <label
                  id="CardPreAnestheticFormUX-label-239"
                  style={{minWidth: "120px", maxWidth: "120px"}}>
                  Proposed operation
                </label>
                <Input
                  disabled={true}
                  fluid={true}
                  id="CardPreAnestheticFormUX-Input-241"
                  value={props.PreAnestheticSequence?.data?.ProposedOperation || ""}>
                </Input>
              </FormField>
              <FormField
                id="CardPreAnestheticFormUX-FormField-249"
                inline={true}
                style={{ marginRight: "10px" }}
                width={4}>
                <label
                  id="CardPreAnestheticFormUX-label-291"
                  style={{minWidth: "95px", maxWidth: "95px", paddingLeft: "40px" }}>
                  Surgeon
                </label>
                <Input
                  disabled={true}
                  fluid={true}
                  id="CardPreAnestheticFormUX-Input-293"
                  value={props.PreAnestheticSequence?.data?.Surgeon || ""}>
                </Input>
              </FormField>
              <FormField
                id="CardPreAnestheticFormUX-FormField-290"
                inline={true}
                style={{ marginRight: "10px" }}
                width={4}>
                <label
                  id="CardPreAnestheticFormUX-label-292"
                  style={{minWidth: "120px", maxWidth: "120px", paddingLeft: "20px"}}>
                  Operative Date
                </label>
                <Input
                  disabled={true}
                  fluid={true}
                  id="CardPreAnestheticFormUX-Input-294"
                  value={props.PreAnestheticSequence?.data?.DateTime || ""}>
                </Input>
              </FormField>
            </FormGroup>
            <FormGroup
              id="CardPreAnestheticFormUX-FormGroup-216"
              inline={true}
              style={{ alignItems: "flex-start" }}>
              <FormField
                id="CardPreAnestheticFormUX-FormField-218"
                inline={true}
                style={{ alignItems: "center" }}
                width={6}>
                <label
                  id="CardPreAnestheticFormUX-label-224"
                  style={{minWidth: "95px", maxWidth: "95px"}}>
                  Anesthesiologist
                </label>
                <Dropdown
                  clearable={true}
                  fluid={true}
                  id="CardPreAnestheticFormUX-Dropdown-230"
                  name="Anesthesiologist"
                  onChange={props.onChangeData}
                  options={props.masterOptions.doctor || []}
                  search={true}
                  selection={true}
                  style={{width: "100%", display: "none"}}
                  value={props.PreAnestheticSequence?.data?.Anesthesiologist || ""}>
                </Dropdown>
                <div
                  id="CardPreAnestheticFormUX-div-250"
                  style={{ width: "100%" }}>
                  {props.SurgeonElement}
                </div>
              </FormField>
              <FormField
                id="CardPreAnestheticFormUX-FormField-233"
                inline={true}
                width={1}>
              </FormField>
              <FormField
                id="CardPreAnestheticFormUX-FormField-219"
                inline={true}
                style={{ alignItems: "flex-start" }}
                width={8}>
                <label
                  id="CardPreAnestheticFormUX-label-225"
                  style={{minWidth: "115px", maxWidth: "115px", marginTop: "7px"}}>
                  Anesthesia nurse
                </label>
                <div
                  id="CardPreAnestheticFormUX-div-234"
                  style={{ width: "100%" }}>
                  {props.AnesthetistElement}
                </div>
              </FormField>
            </FormGroup>
          </Form>
        </div>
      </div>
    )
}


export default CardPreAnestheticFormUX

export const screenPropsDefault = {}

/* Date Time : Thu Mar 13 2025 14:39:26 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "className": {
          "type": "value",
          "value": "--override-disabled --grey"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticForm"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", padding:\"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "--override-disabled --grey"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-div-1"
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#EDF6F9\",width: \"100%\",height:\"50%\",padding:\"20px 0px 10px 10px\",borderRadius: 3,border : \"solid 0.5px \",borderColor:\"#5DBCD2\", ...(props.isPHV && { pointerEvents: \"none\" })}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-div-3"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 3,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-div-4"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"1/3\",marginLeft:\"10px\",marginTop:\"10px\", justifyContent: \"space-between\", display: \"flex\", alignItems: \"center\", marginBottom: \"10px\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "Pre Anesthetic form"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-div-5"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,fontWeight:\"Bold\",fontSize:\"18px\",marginBottom:\"10px\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 156,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-div-156"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\" }"
        }
      },
      "seq": 156,
      "void": false
    },
    {
      "from": null,
      "id": 157,
      "name": "div",
      "parent": 156,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonLog"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-div-157"
        }
      },
      "seq": 157,
      "void": false
    },
    {
      "from": null,
      "id": 158,
      "name": "div",
      "parent": 156,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonPrint"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-div-158"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"none\" }"
        }
      },
      "seq": 158,
      "void": false
    },
    {
      "from": null,
      "id": 160,
      "name": "div",
      "parent": 156,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonConfirm"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-div-160"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAnestheticSequence?.status_label === \"Confirm\" ?  \"none\" : \"\",}"
        }
      },
      "seq": 161,
      "void": false
    },
    {
      "from": null,
      "id": 161,
      "name": "div",
      "parent": 156,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-div-161"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAnestheticSequence?.status_label === \"Confirm\" ?  \"none\" : \"\",}"
        }
      },
      "seq": 160,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 164,
      "name": "Form",
      "parent": 1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-Form-164"
        }
      },
      "seq": 164,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 165,
      "name": "FormGroup",
      "parent": 164,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-FormGroup-165"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 165,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 166,
      "name": "FormField",
      "parent": 165,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-FormField-166"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" }"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 166,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 168,
      "name": "FormField",
      "parent": 165,
      "props": {
        "className": {
          "type": "value",
          "value": "--override-disabled --grey"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-FormField-168"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"center\" }"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 251,
      "void": false
    },
    {
      "from": null,
      "id": 169,
      "name": "label",
      "parent": 166,
      "props": {
        "children": {
          "type": "value",
          "value": "Preanesthetic assessment at"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-label-169"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"100px\", maxWidth: \"100px\"}"
        }
      },
      "seq": 169,
      "void": false
    },
    {
      "from": null,
      "id": 172,
      "name": "label",
      "parent": 168,
      "props": {
        "children": {
          "type": "value",
          "value": "Height"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-label-172"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"45px\", maxWidth: \"45px\" }"
        }
      },
      "seq": 172,
      "void": false
    },
    {
      "from": null,
      "id": 173,
      "name": "label",
      "parent": 168,
      "props": {
        "children": {
          "type": "value",
          "value": "cm"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-label-173"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"30px\", maxWidth: \"30px\", margin: \"0px 10px\"}"
        }
      },
      "seq": 261,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 178,
      "name": "Dropdown",
      "parent": 166,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-Dropdown-178"
        },
        "name": {
          "type": "value",
          "value": "PreanestheticAssessmentAt"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_assessment_at || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.5rem\", width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PreanestheticAssessmentAt || \"\""
        }
      },
      "seq": 178,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 179,
      "name": "Input",
      "parent": 166,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-Input-179"
        },
        "name": {
          "type": "value",
          "value": "PreanestheticAssessmentAtRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PreanestheticAssessmentAtRemark || \"\""
        }
      },
      "seq": 179,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 184,
      "name": "FormGroup",
      "parent": 164,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-FormGroup-184"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 184,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 185,
      "name": "FormField",
      "parent": 184,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-FormField-185"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" }"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 185,
      "void": false
    },
    {
      "from": null,
      "id": 188,
      "name": "label",
      "parent": 185,
      "props": {
        "children": {
          "type": "value",
          "value": "Psychological status"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-label-188"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"100px\", maxWidth: \"100px\"}"
        }
      },
      "seq": 188,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 197,
      "name": "Dropdown",
      "parent": 185,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-Dropdown-197"
        },
        "name": {
          "type": "value",
          "value": "PsychologicalStatus"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_psychological_status || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.5rem\", width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PsychologicalStatus || \"\""
        }
      },
      "seq": 197,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 198,
      "name": "Input",
      "parent": 185,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-Input-198"
        },
        "name": {
          "type": "value",
          "value": "PsychologicalStatusRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PsychologicalStatusRemark || \"\""
        }
      },
      "seq": 198,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 216,
      "name": "FormGroup",
      "parent": 164,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-FormGroup-216"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ alignItems: \"flex-start\" }"
        }
      },
      "seq": 235,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 218,
      "name": "FormField",
      "parent": 216,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-FormField-218"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ alignItems: \"center\" }"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 218,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 219,
      "name": "FormField",
      "parent": 216,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-FormField-219"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ alignItems: \"flex-start\" }"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 233,
      "void": false
    },
    {
      "from": null,
      "id": 224,
      "name": "label",
      "parent": 218,
      "props": {
        "children": {
          "type": "value",
          "value": "Anesthesiologist"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-label-224"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"95px\", maxWidth: \"95px\"}"
        }
      },
      "seq": 224,
      "void": false
    },
    {
      "from": null,
      "id": 225,
      "name": "label",
      "parent": 219,
      "props": {
        "children": {
          "type": "value",
          "value": "Anesthesia nurse"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-label-225"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"115px\", maxWidth: \"115px\", marginTop: \"7px\"}"
        }
      },
      "seq": 225,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 230,
      "name": "Dropdown",
      "parent": 218,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-Dropdown-230"
        },
        "name": {
          "type": "value",
          "value": "Anesthesiologist"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions.doctor || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", display: \"none\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.Anesthesiologist || \"\""
        }
      },
      "seq": 230,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 233,
      "name": "FormField",
      "parent": 216,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-FormField-233"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 219,
      "void": false
    },
    {
      "from": null,
      "id": 234,
      "name": "div",
      "parent": 219,
      "props": {
        "children": {
          "type": "code",
          "value": "props.AnesthetistElement"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-div-234"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 234,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 235,
      "name": "FormGroup",
      "parent": 164,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-FormGroup-235"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 216,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 236,
      "name": "FormField",
      "parent": 235,
      "props": {
        "className": {
          "type": "value",
          "value": "--override-disabled --grey"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-FormField-236"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" }"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 236,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 237,
      "name": "FormField",
      "parent": 235,
      "props": {
        "className": {
          "type": "value",
          "value": "--override-disabled --grey"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-FormField-237"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" }"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 237,
      "void": false
    },
    {
      "from": null,
      "id": 238,
      "name": "label",
      "parent": 236,
      "props": {
        "children": {
          "type": "value",
          "value": "DX"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-label-238"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"95px\", maxWidth: \"95px\"}"
        }
      },
      "seq": 238,
      "void": false
    },
    {
      "from": null,
      "id": 239,
      "name": "label",
      "parent": 237,
      "props": {
        "children": {
          "type": "value",
          "value": "Proposed operation"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-label-239"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"120px\", maxWidth: \"120px\"}"
        }
      },
      "seq": 239,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 240,
      "name": "Input",
      "parent": 236,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-Input-240"
        },
        "readOnly": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.DX || \"\""
        }
      },
      "seq": 240,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 241,
      "name": "Input",
      "parent": 237,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-Input-241"
        },
        "readOnly": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.ProposedOperation || \"\""
        }
      },
      "seq": 241,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 249,
      "name": "FormField",
      "parent": 235,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-FormField-249"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" }"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 249,
      "void": false
    },
    {
      "from": null,
      "id": 250,
      "name": "div",
      "parent": 218,
      "props": {
        "children": {
          "type": "code",
          "value": "props.SurgeonElement"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-div-250"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 250,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 251,
      "name": "FormField",
      "parent": 165,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-FormField-251"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"center\" }"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 168,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 252,
      "name": "FormField",
      "parent": 165,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-FormField-252"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"center\" }"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 252,
      "void": false
    },
    {
      "from": null,
      "id": 253,
      "name": "label",
      "parent": 251,
      "props": {
        "children": {
          "type": "value",
          "value": "Body weight"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-label-253"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"80px\", maxWidth: \"80px\", fontSize: \"13px\"}"
        }
      },
      "seq": 253,
      "void": false
    },
    {
      "from": null,
      "id": 254,
      "name": "label",
      "parent": 251,
      "props": {
        "children": {
          "type": "value",
          "value": "kg"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-label-254"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"30px\", maxWidth: \"30px\", margin: \"0px 10px\"}"
        }
      },
      "seq": 259,
      "void": false
    },
    {
      "from": null,
      "id": 255,
      "name": "label",
      "parent": 252,
      "props": {
        "children": {
          "type": "value",
          "value": "BMI"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-label-255"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"35px\", maxWidth: \"35px\" }"
        }
      },
      "seq": 255,
      "void": false
    },
    {
      "from": null,
      "id": 256,
      "name": "label",
      "parent": 252,
      "props": {
        "children": {
          "type": "value",
          "value": "kg/m2"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-label-256"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"40px\", maxWidth: \"40px\", margin: \"0px 10px\"}"
        }
      },
      "seq": 263,
      "void": false
    },
    {
      "from": null,
      "id": 259,
      "name": "div",
      "parent": 251,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-div-259"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"80px\", maxWidth: \"80px\"}"
        }
      },
      "seq": 257,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 260,
      "name": "Input",
      "parent": 259,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-Input-260"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.BodyWeight || \"\""
        }
      },
      "seq": 260,
      "void": false
    },
    {
      "from": null,
      "id": 261,
      "name": "div",
      "parent": 168,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-div-261"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"80px\", maxWidth: \"80px\"}"
        }
      },
      "seq": 176,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 262,
      "name": "Input",
      "parent": 261,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-Input-262"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.Height || \"\""
        }
      },
      "seq": 262,
      "void": false
    },
    {
      "from": null,
      "id": 263,
      "name": "div",
      "parent": 252,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-div-263"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"80px\", maxWidth: \"80px\"}"
        }
      },
      "seq": 258,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 264,
      "name": "Input",
      "parent": 263,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-Input-264"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.BMI || \"\""
        }
      },
      "seq": 264,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 265,
      "name": "FormField",
      "parent": 165,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-FormField-265"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"center\" }"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 265,
      "void": false
    },
    {
      "from": null,
      "id": 266,
      "name": "label",
      "parent": 265,
      "props": {
        "children": {
          "type": "value",
          "value": "BP"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-label-266"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"65px\", maxWidth: \"65px\"}"
        }
      },
      "seq": 266,
      "void": false
    },
    {
      "from": null,
      "id": 267,
      "name": "label",
      "parent": 265,
      "props": {
        "children": {
          "type": "value",
          "value": "mmHg"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-label-267"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"45px\", maxWidth: \"45px\", margin: \"0px 10px\"}"
        }
      },
      "seq": 268,
      "void": false
    },
    {
      "from": null,
      "id": 268,
      "name": "div",
      "parent": 265,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-div-268"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"80px\", maxWidth: \"80px\"}"
        }
      },
      "seq": 267,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 269,
      "name": "Input",
      "parent": 268,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-Input-269"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.BP || \"\""
        }
      },
      "seq": 269,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 270,
      "name": "FormField",
      "parent": 184,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-FormField-270"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"center\" }"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 270,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 271,
      "name": "FormField",
      "parent": 184,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-FormField-271"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"center\" }"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 271,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 272,
      "name": "FormField",
      "parent": 184,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-FormField-272"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"center\" }"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 272,
      "void": false
    },
    {
      "from": null,
      "id": 273,
      "name": "label",
      "parent": 270,
      "props": {
        "children": {
          "type": "value",
          "value": "Pulse"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-label-273"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"80px\", maxWidth: \"80px\", fontSize: \"13px\"}"
        }
      },
      "seq": 273,
      "void": false
    },
    {
      "from": null,
      "id": 274,
      "name": "label",
      "parent": 270,
      "props": {
        "children": {
          "type": "value",
          "value": "/min"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-label-274"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"30px\", maxWidth: \"30px\", margin: \"0px 10px\"}"
        }
      },
      "seq": 279,
      "void": false
    },
    {
      "from": null,
      "id": 275,
      "name": "label",
      "parent": 271,
      "props": {
        "children": {
          "type": "value",
          "value": "RR"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-label-275"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"45px\", maxWidth: \"45px\" }"
        }
      },
      "seq": 275,
      "void": false
    },
    {
      "from": null,
      "id": 276,
      "name": "label",
      "parent": 271,
      "props": {
        "children": {
          "type": "value",
          "value": "/min"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-label-276"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"30px\", maxWidth: \"30px\", margin: \"0px 10px\"}"
        }
      },
      "seq": 280,
      "void": false
    },
    {
      "from": null,
      "id": 277,
      "name": "label",
      "parent": 272,
      "props": {
        "children": {
          "type": "value",
          "value": "Temp"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-label-277"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"35px\", maxWidth: \"35px\" }"
        }
      },
      "seq": 277,
      "void": false
    },
    {
      "from": null,
      "id": 278,
      "name": "label",
      "parent": 272,
      "props": {
        "children": {
          "type": "value",
          "value": "°C"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-label-278"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"40px\", maxWidth: \"40px\", margin: \"0px 10px\"}"
        }
      },
      "seq": 281,
      "void": false
    },
    {
      "from": null,
      "id": 279,
      "name": "div",
      "parent": 270,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-div-279"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"80px\", maxWidth: \"80px\"}"
        }
      },
      "seq": 274,
      "void": false
    },
    {
      "from": null,
      "id": 280,
      "name": "div",
      "parent": 271,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-div-280"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"80px\", maxWidth: \"80px\"}"
        }
      },
      "seq": 276,
      "void": false
    },
    {
      "from": null,
      "id": 281,
      "name": "div",
      "parent": 272,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-div-281"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"80px\", maxWidth: \"80px\"}"
        }
      },
      "seq": 278,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 282,
      "name": "Input",
      "parent": 281,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-Input-282"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PsychologicalStatusTemp || \"\""
        }
      },
      "seq": 282,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 283,
      "name": "Input",
      "parent": 280,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-Input-283"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.RR || \"\""
        }
      },
      "seq": 283,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 284,
      "name": "Input",
      "parent": 279,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-Input-284"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.Pulse || \"\""
        }
      },
      "seq": 284,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 285,
      "name": "FormField",
      "parent": 184,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-FormField-285"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"center\" }"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 285,
      "void": false
    },
    {
      "from": null,
      "id": 286,
      "name": "label",
      "parent": 285,
      "props": {
        "children": {
          "type": "value",
          "value": "O2 Sat RA"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-label-286"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"65px\", maxWidth: \"65px\"}"
        }
      },
      "seq": 286,
      "void": false
    },
    {
      "from": null,
      "id": 287,
      "name": "label",
      "parent": 285,
      "props": {
        "children": {
          "type": "value",
          "value": "%"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-label-287"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"45px\", maxWidth: \"45px\", margin: \"0px 10px\"}"
        }
      },
      "seq": 288,
      "void": false
    },
    {
      "from": null,
      "id": 288,
      "name": "div",
      "parent": 285,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-div-288"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"80px\", maxWidth: \"80px\"}"
        }
      },
      "seq": 287,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 289,
      "name": "Input",
      "parent": 288,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-Input-289"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.O2SatRA || \"\""
        }
      },
      "seq": 289,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 290,
      "name": "FormField",
      "parent": 235,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-FormField-290"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" }"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 290,
      "void": false
    },
    {
      "from": null,
      "id": 291,
      "name": "label",
      "parent": 249,
      "props": {
        "children": {
          "type": "value",
          "value": "Surgeon"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-label-291"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"95px\", maxWidth: \"95px\", paddingLeft: \"40px\" }"
        }
      },
      "seq": 291,
      "void": false
    },
    {
      "from": null,
      "id": 292,
      "name": "label",
      "parent": 290,
      "props": {
        "children": {
          "type": "value",
          "value": "Operative Date"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-label-292"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"120px\", maxWidth: \"120px\", paddingLeft: \"20px\"}"
        }
      },
      "seq": 292,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 293,
      "name": "Input",
      "parent": 249,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-Input-293"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.Surgeon || \"\""
        }
      },
      "seq": 293,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 294,
      "name": "Input",
      "parent": 290,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-Input-294"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.DateTime || \"\""
        }
      },
      "seq": 294,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": true,
  "memo": false,
  "name": "CardPreAnestheticFormUX",
  "project": "IsHealth_by_Front-end",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
